<template>
  <DefaultCard wide no-title class="flex-grow-1">
    <v-card-text data-testid="home-card-text">
      <h2>{{ $t('tfaManagement') }}</h2>
      <v-data-table
        v-model="selected"
        :headers="tableHeaders"
        :items="devices"
        :search="search"
        :must-sort="true"
        :server-items-length="totalDevices"
        :loading="loading"
        :options.sync="options"
        :footer-props="{ itemsPerPageOptions: [10, 25, 100, 1000] }"
        item-key="id"
      >
        <template v-slot:top>
          <v-toolbar flat>
            <v-text-field
              v-model="search"
              append-icon="fa-search"
              :label="$t('search')"
              single-line
              clearable
              hide-details
            ></v-text-field>
            <v-spacer></v-spacer>

            <v-dialog v-model="deleteTOTPDevice" max-width="500px">
              <DeleteTOTPDeviceForm
                :device="deviceToDelete"
                @close-dialog="closeDeleteDeviceDialog"
                @reload-devices="fetchTOTPDevices"
              ></DeleteTOTPDeviceForm>
            </v-dialog>
          </v-toolbar>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-tooltip top :open-delay="500">
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                data-testid="reset-2fa-action"
                small
                class="mr-2"
                v-bind="attrs"
                v-on="on"
                @click="showDeleteTOTPDevice(item)"
              >
                fa-undo
              </v-icon>
            </template>
            <span>{{ $t('reset2FA') }}</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </v-card-text>
  </DefaultCard>
</template>

<script>
import { branding } from '@/components/mixins'
import DefaultCard from '@/components/DefaultCard'
import DeleteTOTPDeviceForm from '@/components/DeleteTOTPDeviceForm'
import api from '@/api'
import debounce from 'lodash/debounce'

export default {
  components: { DefaultCard, DeleteTOTPDeviceForm },
  mixins: [branding],
  data() {
    return {
      deleteTOTPDevice: false,
      deviceToDelete: null,
      search: '',
      loading: false,
      options: {},
      totalDevices: 0,
      devices: [],
      tableHeaders: [
        { text: this.$t('username'), value: 'username' },
        { text: this.$t('email'), value: 'email' },
        { text: this.$t('tfaNumFailures'), value: 'throttling_failure_count' },
        { text: this.$t('actions'), value: 'actions', sortable: false, width: 130 },
      ],
      selected: [],
    }
  },
  watch: {
    options: {
      handler() {
        this.fetchTOTPDevices()
      },
      deep: true,
    },
    search() {
      this.fetchTOTPDevices()
    },
  },
  mounted() {
    this.fetchTOTPDevices()
  },
  methods: {
    fetchTOTPDevices: debounce(async function fetchTOTPDevices() {
      this.loading = true
      try {
        const resp = await api.get('/api/totp-devices/', {
          params: {
            page: this.options.page,
            page_size: this.options.itemsPerPage,
            sort_by: this.options.sortBy,
            sort_desc: this.options.sortDesc,
            search: this.search,
          },
        })
        this.devices = resp.data.results
        this.totalDevices = resp.data.count
      } catch (error) {
        this.devices = []
        this.totalDevices = 0
      }

      this.loading = false
    }, 300),
    showDeleteTOTPDevice(device) {
      this.deviceToDelete = device
      this.deleteTOTPDevice = true
    },
    closeDeleteDeviceDialog() {
      this.deleteTOTPDevice = false
      this.deviceToDelete = {}
    },
  },
}
</script>
