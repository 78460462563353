<template>
  <div class="d-flex flex-column">
    <v-text-field
      v-bind="$attrs"
      type="password"
      prepend-icon="fa-key"
      :value="value"
      data-testid="password-field-input"
      @input="validate"
    />
    <div class="mt-2 pl-7" data-testid="password-field-messages">
      <div
        v-for="(isValid, message) in validators"
        :key="message"
        :class="isValid && !isPasswordEmpty ? 'success--text' : 'error--text'"
        class="d-flex align-center mb-1"
      >
        <v-icon v-if="isValid && !isPasswordEmpty" color="success" x-small class="password-check-icon">
          fa-check
        </v-icon>
        <v-icon v-else color="error" x-small class="password-check-icon"> fa-times </v-icon>
        <span class="caption">
          {{ message }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import isEmpty from 'lodash/isEmpty'
import debounce from 'lodash/debounce'
import api from '@/api'

export default {
  name: 'PasswordField',
  inheritAttrs: false,
  props: {
    value: {
      type: String,
      default: () => '',
    },
    userInformation: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      validators: {},
    }
  },
  computed: {
    validatorCount() {
      return Object.keys(this.validators).length
    },
    isPasswordEmpty() {
      return isEmpty(this.value)
    },
  },
  async mounted() {
    await this.checkPassword(this.value)
  },
  methods: {
    checkPassword: debounce(async function checkPassword(password) {
      const { data } = await api.post('/api/registration/check-password', { password, ...this.userInformation })
      this.validators = data
    }, 250),
    validate(password) {
      this.$emit('input', password)
      this.checkPassword(password)
    },
  },
}
</script>

<style lang="scss" scoped>
.password-check-icon {
  min-width: 30px;
  padding-right: 10px;
}
</style>
