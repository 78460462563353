<template>
  <v-app>
    <v-main>
      <v-container
        :class="{ 'px-0': $vuetify.breakpoint.xs }"
        class="d-flex flex-column align-center justify-center"
        style="height: 100%"
      >
        <div class="d-flex justify-center mb-6">
          <img :src="logoURL" class="logo" />
        </div>
        <router-view />
      </v-container>
    </v-main>
    <v-component :is="footer" v-if="componentExists(footer)" />
    <Notification />
  </v-app>
</template>

<script>
import { branding } from '@/components/mixins'
import Notification from '@/components/Notification'

export default {
  name: 'App',
  components: { Notification },
  mixins: [branding],
}
</script>
