<template>
  <DefaultCard>
    <template #title>
      {{ $t('resetPassword') }}
    </template>
    <v-form @submit.prevent="resetPassword">
      <v-card-text v-if="errors.nonfield">
        <span v-for="error in errors.nonfield" :key="error" class="error--text body-1">
          {{ error }}
        </span>
      </v-card-text>
      <template v-if="isValidToken">
        <v-card-text>
          <p>
            {{ $t('provideNewPassword') }}
          </p>
          <PasswordField
            v-model="payload.new_password"
            :label="$t('newPassword')"
            :error-messages="errors.new_password"
            :user-information="userInformation"
          />
          <v-text-field
            v-model="payload.reentered_password"
            class="mt-6"
            type="password"
            prepend-icon="fa-key"
            :label="$t('reenterPassword')"
            :error-messages="errors.reentered_password"
          />
        </v-card-text>
        <v-card-actions>
          <v-btn type="submit" color="primary" depressed block>{{ $t('reset') }}</v-btn>
        </v-card-actions>
      </template>
    </v-form>
  </DefaultCard>
</template>

<script>
import { branding } from '@/components/mixins'
import api from '@/api'
import PasswordField from '@/components/PasswordField'
import DefaultCard from '@/components/DefaultCard'

export default {
  components: { PasswordField, DefaultCard },
  mixins: [branding],
  data() {
    return {
      payload: {
        new_password: '',
        reentered_password: '',
      },
      isValidToken: false,
      errors: {},
    }
  },
  computed: {
    userInformation() {
      return { password_reset_user_id: this.$route.params.user_id, password_reset_token: this.$route.params.token }
    },
  },
  async created() {
    try {
      await api.post(`/api/registration/validate-token/reset/${this.$route.params.user_id}/${this.$route.params.token}`)
    } catch ({
      response: {
        data: {
          validation: { errors },
        },
      },
    }) {
      this.errors = errors
      return
    }
    this.isValidToken = true
  },
  methods: {
    async resetPassword() {
      try {
        await api.post(
          `/api/registration/reset-password-confirm/${this.$route.params.user_id}/${this.$route.params.token}`,
          this.payload,
        )
        this.notifySuccess(this.$t('notifyPasswordReset'))
        this.$router.replace({ name: 'home' })
      } catch ({ response: { data: errors } }) {
        this.errors = errors
      }
    },
  },
}
</script>
