import { mapState } from 'vuex'
import { getAssetURL } from '@/theme'

export const branding = {
  computed: {
    logoURL() {
      return getAssetURL({ theme: this.theme, asset: 'logo.svg' })
    },
    footer() {
      return `${this.theme}-Footer`
    },
    externalLoginText() {
      return `${this.theme}-ExternalLoginText`
    },
    ...mapState('config', {
      theme: (state) => state.config.theme,
      title: (state) => state.config.title,
      components: (state) => state.components,
    }),
  },
  methods: {
    componentExists(name) {
      return this.components.includes(name)
    },
  },
}
