<template>
  <DefaultCard>
    <template #title>
      {{ $t('disableTwoFactorAuthentication') }}
    </template>
    <v-form @submit.prevent="disableTOTP">
      <v-card-text>
        <span>
          {{ $t('pleaseEnterPasswordToDisable2FA') }}
        </span>
        <v-text-field
          id="password"
          v-model="password"
          :error-messages="errorMessages"
          name="password"
          :label="$t('password')"
          type="password"
          prepend-icon="fa-key"
          autofocus
        ></v-text-field>
      </v-card-text>
      <v-card-actions class="justify-end">
        <v-btn depressed :to="{ name: 'home' }" color="accent">{{ $t('cancel') }}</v-btn>
        <v-btn depressed type="submit" color="primary">{{ $t('disable') }}</v-btn>
      </v-card-actions>
    </v-form>
  </DefaultCard>
</template>

<script>
import api from '@/api'
import DefaultCard from '@/components/DefaultCard'

export default {
  name: 'DisableTOTP',
  components: { DefaultCard },
  data: () => ({
    password: '',
    errorMessages: [],
  }),
  methods: {
    disableTOTP() {
      api
        .delete('/api/totp', { data: { password: this.password } })
        .then(() => {
          this.$router.push({ name: 'home' })
        })
        .catch((error) => {
          if (error.response.data.detail === 'Wrong password') {
            this.errorMessages = [this.$t('wrongPassword')]
          } else if (error.response.status === 403) {
            this.errorMessages = [this.$t('disable2FAForbidden')]
          }
        })
    },
  },
}
</script>
