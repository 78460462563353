<template>
  <DefaultCard>
    <template #title>
      {{ $t('onboardTitle') }}
    </template>
    <v-card-text v-if="errors.nonfield">
      <span v-for="error in errors.nonfield" :key="error" class="error--text body-1">
        {{ error }}
      </span>
    </v-card-text>
    <template v-if="isValidToken">
      <v-form @submit.prevent="submit">
        <v-card-text>
          <p>
            {{ $t('provideNewPassword') }}
          </p>
          <v-text-field v-model="username" disabled prepend-icon="fa-user" :label="$t('username')" />
          <PasswordField
            v-model="payload.new_password"
            :label="$t('newPassword')"
            :error-messages="errors.new_password"
          />
          <v-text-field
            v-model="payload.reentered_password"
            class="mt-6"
            type="password"
            prepend-icon="fa-key"
            :label="$t('reenterPassword')"
            :error-messages="errors.reentered_password"
          />
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn type="submit" color="primary" block depressed :loading="loading">
            {{ $t('onboardAction') }}
          </v-btn>
        </v-card-actions>
      </v-form>
    </template>
  </DefaultCard>
</template>

<script>
import api from '@/api'
import { branding } from '@/components/mixins'
import PasswordField from '@/components/PasswordField'
import DefaultCard from '@/components/DefaultCard'

export default {
  name: 'Onboard',
  components: { PasswordField, DefaultCard },
  mixins: [branding],
  data() {
    return {
      payload: {
        new_password: '',
        reentered_password: '',
      },
      username: '',
      isValidToken: false,
      loading: false,
      errors: {},
    }
  },
  computed: {
    apiUrl() {
      return `/api/registration/onboarding?t=${this.$route.query.t}`
    },
  },
  async created() {
    // Validate the token initially.
    if (!this.$route.query.t) {
      this.showInvalidLinkError()
      return
    }
    try {
      const {
        data: { username },
      } = await api.get(this.apiUrl)
      this.username = username
      this.isValidToken = true
    } catch (response) {
      this.showInvalidLinkError()
    }
  },
  methods: {
    async submit() {
      this.loading = true
      try {
        await api.post(this.apiUrl, this.payload)
        this.notifySuccess(this.$t('notifyOnboardSuccess'))
        await this.$router.replace({ name: 'login' })
      } catch ({ response: { data: errors } }) {
        this.errors = errors
      }
      this.loading = false
    },
    showInvalidLinkError() {
      // Use format of backend form validation for the error message.
      this.errors = { nonfield: [this.$t('invalidLink')] }
    },
  },
}
</script>
