// prettier-ignore
export default {
  'apple-id': 'Apple',
  'azuread-v2-tenant-oauth2': 'Azure AD',
  'google-openidconnect': 'Google',
  'microsoft-graph': 'Microsoft',
  actions: 'Actions',
  activate: 'Activate',
  add: 'Add',
  addGroup: 'Add Group',
  addUser: 'Add User',
  anErrorOccurred: 'An error ocurred:',
  applications: 'Applications',
  assignGroups: 'Assign groups',
  assignUsers: 'Assign users',
  backToLogin: 'Back to login page',
  cancel: 'Cancel',
  change: 'Change',
  changePassword: 'Change password',
  close: 'Close',
  containsInvalidChars: 'Input contains invalid characters',
  continue: 'Continue',
  createAccount: 'Create an account',
  currentPassword: 'Current password',
  currentSession: 'Current session',
  delete: 'Delete',
  deleteGroup: 'Delete Group',
  deleteGroupConfirm: 'Do you really want to delete the group {group}?',
  deleteUser: 'Delete User',
  deleteUserConfirm: 'Do you really want to delete the user {user}?',
  disable2FAForbidden: 'Disabling two-factor authentication is not allowed.',
  disable: 'Disable',
  disabled: 'disabled',
  disableTwoFactorAuthentication: 'Disable two-factor authentication',
  editGroup: 'Edit Group',
  editUser: 'Edit User',
  email: 'E-mail',
  emailAddress: 'E-mail address',
  enabled: 'enabled',
  endSession: 'End session',
  enterCode: 'Enter the 6 digit code generated by your app here:',
  firstName: 'First name',
  forgotPassword: 'Forgot password?',
  group: 'Group',
  groupId: 'Id',
  groupManagement: 'Group management',
  groupName: 'Name',
  help: 'Help',
  inputRequired: 'Input required',
  invalidCode: 'Invalid code! Please try again.',
  invalidCredentials: 'Invalid username or password.',
  invalidEmail: 'Invalid email',
  invalidLink: 'Invalid link.',
  lastName: 'Last name',
  location: 'City',
  loggedInAs: 'Logged-in as {user}.',
  loggedOut: 'Logged out',
  loggedOutSuccessfully: 'You were successfully logged out.',
  loggingOut: 'You will be logged out',
  login: 'Login',
  loginLink: 'You already have an account? Sign up.',
  loginWith: 'Login with',
  loginWithExistingAccount: 'or {login} if you already have an account.',
  loginWithoutSSO: 'Login without SSO',
  logout: 'Logout',
  logoutFailed: 'Logout failed',
  logoutNotSuccessful: 'Logout was not successful.',
  manage2fa: 'Manage 2FA',
  manageGroups: 'Manage groups',
  manageUsers: 'Manage users',
  mobileNumber: 'Mobile number',
  newPassword: 'New password',
  notifyOnboardSuccess: 'Account successfully set up.',
  notifyPasswordChanged: 'Password successfully changed.',
  notifyPasswordReset: 'Password successfully reset.',
  notifyPasswordResetMail: 'An email with a link to reset the password was sent to the provided address, if a valid account exists.',
  onboardAction: 'Setup',
  onboardTitle: 'Setup account',
  organization: 'Organization',
  orLoginWith: 'or login with',
  password: 'Password',
  passwortResetProvideEmail: 'Please provide your e-mail address to get a link to reset your password.',
  phoneNumber: 'Phone number',
  pleaseEnterPasswordToDisable2FA: 'Please enter your password to disable two-factor authentication.',
  provideNewPassword: 'Please enter your new password here, and confirm it.',
  recentActivity: 'Most recent activity',
  recommendAuthenticatorApp: "If you don't have an authenticator app yet, you need to install one on your mobile phone.",
  reenterPassword: 'Reenter new password',
  registrationConditions: 'By registering, you confirm that you have read and accepted our terms of use and privacy policy.',
  requestLink: 'Request link',
  reset: 'Reset',
  reset2FA: 'Reset 2FA',
  reset2FAConfirm: 'Do you really want to reset two-factor authentication for the user {user}?',
  reset2FAConfirmHelp: 'The user will need to set up 2FA for their account again afterwards.',
  resetPassword: 'Reset password',
  saml: 'SAML',
  save: 'Save',
  search: 'Search',
  selfRegistrationEmailAlreadyRegistered: 'This email address is already registered. Log in at the registration form.',
  selfRegistrationEmailSent: 'We have sent you an email to {email}. Click on the link in the email to complete the registration.',
  selfRegistrationInvalidEmailAddress: 'The email address provided is not valid.',
  selfRegistrationLink: 'Create a new account',
  selfRegistrationRegister: 'Proceed',
  selfRegistrationText: 'Create a new account here. We will send you a confirmation link to the email address you provided.',
  selfRegistrationTitle: 'Registration',
  sessions: 'Sessions',
  setPassword: 'Set password',
  setupTwoFactorAuthentication: 'Setup two-factor authentication',
  signup: 'Sign up',
  singleSignOnFailed: 'Single Sign On failed. Please login by entering your username and password.',
  stayLoggedIn: 'Stay logged-in',
  street: 'Street',
  switchToLogin: 'login',
  tfaActivated: {
    twoFactorAuthenticationActivated: 'Two-factor authentication activated.',
    twoFactorAuthenticationActivatedHelp: 'In the future, you will now be prompted to enter a 6 digit 2FA code after entering your password. To get that code when prompted, please open the <strong>authenticator app</strong> you just used to setup this account.<br/><br/>Then select the code for the correct account from the app, and enter it where prompted.',
  },
  tfaEntryPrompt: {
    enterCodePrompt: 'Please enter the 2FA code.',
    codeFromSMSHelp: "You've set up two-factor authentication via SMS for this account. Please enter the code that you received by SMS.",
    codeFromTOTPAppHelp: "You've set up two-factor authentication via an <strong>authenticator app</strong> for this account. To get the code, please open the authenticator app on your phone that you've used to set up this account (e.g. Authy, Google Authenticator, or Microsoft Authenticator).",
    codeFromTOTPAppSelectAccount: "Then select the code for the correct account from the app, and enter it here.",
  },
  tfaManagement: 'Manage two-factor authentication',
  tfaSetup: {
    "pleaseScanBarcode": "Please scan the displayed QR code with your <strong>authenticator app</strong>, or enter the following key manually:",
    "installTOTPApp": "If you don't have an authenticator app yet, you need to <strong>install one on your phone</strong>. If you're unsure which app to pick, we recommend one of these:",
    "noPaidTOTPApp": "Note: These apps are <strong>free</strong>. There is no need to install any paid app.",
    "setupTOTPApp": "After installing an authenticator app on your phone, please open it, and select the action to set up 2FA for <strong>a new account</strong> using a QR code. This action is usually called something like <em>\"Set up new account\"</em> or <em>\"Scan QR code \"</em>.",
  },
  tfaNumFailures: 'Failed 2FA attempts',
  title: 'Title',
  tryAgain: 'Try again',
  twoFactorAuthentication: 'Two-factor authentication',
  twoFactorNotSetup: 'Two-factor authentication is required but not setup. Login not possible.',
  twoFactorSetup: 'Two-factor authentication is required but not setup. Please follow the instructions to setup two-factor authentication now.',
  userManagement: 'User management',
  username: 'Username',
  verify: 'Verify',
  wrongPassword: 'Wrong password',
  zipCode: 'ZIP Code',
  approveChangeEmail: 'Change e-mail address',
  changeEmailApproved: 'E-mail address has been changed.',
  changeEmailDone: 'Done',
  changeEmail: 'Change e-mail address',
  successChangeEmailRequest: 'An email to change the email address has been sent to {email}.',
  inquiryNotPossible: 'It could not be determined whether the user may be deleted.',
  delete_user: 'Delete account',
  delete_user_hint:
    'Your account and the associated data will be deleted. You will no longer be able to log in to the app with this account.',
  confirm_delete_user_hint: 'You are about to delete your account. This action cannot be undone.',
  confirm_delete_user: 'Delete account permanently',
  danger_zone: 'Danger Zone',
}
