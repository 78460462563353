<template>
  <DefaultCard>
    <template #title>
      {{ $t('changeEmail') }}
    </template>
    <v-form @submit.prevent="changeEmail">
      <template v-if="!success">
        <v-card-text v-if="errors.nonfield">
          <span v-for="error in errors.nonfield" :key="error" class="error--text body-2" style="white-space: pre-line">
            {{ error }}
          </span>
        </v-card-text>
        <v-card-text>
          <v-text-field
            v-model="payload.email"
            type="email"
            prepend-icon="fa-envelope"
            :label="$t('email')"
            :error-messages="errors.email"
            autofocus
          />
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn depressed :to="{ name: 'home' }" color="accent" min-width="120">{{ $t('cancel') }}</v-btn>
          <v-btn depressed :disabled="!isCompleted" :loading="loading" type="submit" color="primary" min-width="120">{{
            $t('change')
          }}</v-btn>
        </v-card-actions>
      </template>
      <v-card-text v-if="success" class="success--text">
        {{ $t('successChangeEmailRequest', { email: payload.email }) }}
      </v-card-text>
    </v-form>
  </DefaultCard>
</template>

<script>
import { branding } from '@/components/mixins'
import api from '@/api'
import DefaultCard from '@/components/DefaultCard'

export default {
  components: { DefaultCard },
  mixins: [branding],
  data() {
    return {
      loading: false,
      success: false,
      payload: {
        email: '',
      },
      errors: {},
    }
  },
  computed: {
    isCompleted() {
      return this.payload.email.includes('@')
    },
  },
  methods: {
    async changeEmail() {
      this.loading = true
      try {
        await api.post('/api/change-email', this.payload)
        this.success = true
      } catch ({ response: { data: errors } }) {
        this.errors = errors
      } finally {
        this.loading = false
      }
    },
  },
}
</script>
