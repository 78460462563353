// prettier-ignore
export default {
  'apple-id': 'Apple',
  'azuread-v2-tenant-oauth2': 'Azure AD',
  'google-openidconnect': 'Google',
  'microsoft-graph': 'Microsoft',
  actions: 'Actions',
  activate: 'Activer',
  add: 'Ajouter',
  addGroup: 'Ajouter un groupe',
  addUser: 'Ajouter un utilisateur',
  anErrorOccurred: "Une erreur s'est produite:",
  applications: 'Applications',
  assignGroups: 'Ajouter à des groupes',
  assignUsers: 'Gérer les membres du groupe',
  backToLogin: "Retour à l'enregistrement",
  cancel: 'Annuler',
  change: 'Modifier',
  changePassword: 'Modifier le mot de passe',
  close: 'Fermer',
  containsInvalidChars: "L'entrée contient des caractères invalides",
  continue: 'Continuer',
  createAccount: 'Créer un compte utilisateur',
  currentPassword: 'Mot de passe courant',
  currentSession: 'Session courante',
  delete: 'Supprimer',
  deleteGroup: 'Supprimer le groupe',
  deleteGroupConfirm: 'Voulez-vous vraiment supprimer le groupe {group}?',
  deleteUser: "Supprimer l'utilisateur",
  deleteUserConfirm: "Voulez-vous vraiment supprimer l'utilisateur {user}?",
  disable2FAForbidden: "La désactivation de l'authentification à deux facteurs n'est pas autorisée.",
  disable: 'Désactiver',
  disabled: 'désactivée',
  disableTwoFactorAuthentication: "Désactiver l'authentification à deux facteurs",
  editGroup: 'Modifier le groupe',
  editUser: "Modifier l'utilisateur",
  email: 'E-mail',
  emailAddress: 'Adresse e-mail',
  enabled: 'activée',
  endSession: 'Fermer la session',
  enterCode: "Veuillez saisir votre code à 6 chiffres généré par votre app d'authentification",
  firstName: 'Prénom',
  forgotPassword: 'Mot de passe oublié?',
  group: 'Groupe',
  groupId: 'Id',
  groupManagement: 'Gestion des groupes',
  groupName: 'Nom',
  help: 'Aide',
  inputRequired: 'Champ requis',
  invalidCode: 'Code invalide! Veuillez réessayer.',
  invalidCredentials: "Nom d'utilisateur ou mot de passe invalide.",
  invalidEmail: 'Adresse e-mail invalide',
  invalidLink: 'Lien invalide.',
  lastName: 'Nom',
  location: 'Lieu',
  loggedInAs: 'Connecté en tant que {user}.',
  loggedOut: 'Déconnecté',
  loggedOutSuccessfully: 'Vous vous êtes déconnecté(e) avec succès.',
  loggingOut: 'Vous serez déconnecté',
  login: 'Connexion',
  loginLink: 'Vous avez déjà un compte ? Connectez-vous',
  loginTitle: 'Connexion au {title}',
  loginWith: 'Se connecter avec',
  loginWithExistingAccount: "ou {login} si vous disposez déjà d'un compte.",
  loginWithoutSSO: 'Se connecter sans SSO',
  logout: 'Déconnexion',
  logoutFailed: 'Échec de la tentative de déconnexion',
  logoutNotSuccessful: 'La déconnexion a échoué',
  manage2fa: 'Administrer 2FA',
  manageGroups: 'Administrer groupes',
  manageUsers: 'Administrer utilisateurs',
  mobileNumber: 'Numéro de portable',
  newPassword: 'Nouveau mot de passe',
  notifyOnboardSuccess: 'Compte configuré avec succès.',
  notifyPasswordChanged: 'Le mot de passe a été modifié',
  notifyPasswordReset: 'Le mot de passe a été réinitialisé.',
  notifyPasswordResetMail:
    "Un email contenant un lien pour réinitialiser le mot de passe a été envoyé à l'adresse fournie, pour autant qu'elle existe.",
  onboardAction: 'Valider',
  onboardTitle: 'Configurer le compte',
  organization: 'Organisation',
  orLoginWith: 'ou se connecter avec',
  password: 'Mot de passe',
  passwortResetProvideEmail:
    'Veuillez saisir votre adresse email pour obtenir un lien permettant de réinitialiser votre mot de passe.',
  phoneNumber: 'Numéro de téléphone',
  pleaseEnterPasswordToDisable2FA:
    "Veuillez saisir votre mot de passe pour désactiver l'authentification à deux facteurs",
  provideNewPassword: 'Veuillez saisir votre nouveau mot de passe ici et confirmez-le.',
  recentActivity: 'Activité la plus récente',
  recommendAuthenticatorApp:
    "Si vous ne disposez pas encore d'un authentificateur, il vous faut en installer un sur votre téléphone mobile.",
  reenterPassword: 'Répétez le nouveau mot de passe',
  requestLink: 'Demander un lien',
  reset: 'Réinitialiser',
  reset2FA: 'Réinitialiser A2F',
  reset2FAConfirm: "Voulez-vous vraiment réinitialiser l'authentification à deux facteurs pour l'utilisateur {user}?",
  reset2FAConfirmHelp: "L'utilisateur devra ensuite reconfigurer l'A2F pour son compte.",
  resetPassword: 'Réinitialiser le mot de passe',
  saml: 'SAML',
  save: 'Sauver',
  search: 'Recherche',
  selfRegistrationEmailAlreadyRegistered:
    "Cette adresse électronique est déjà enregistrée. Connectez-vous au formulaire d'inscription.",
  selfRegistrationEmailSent:
    "Nous vous avons envoyé un courrier électronique à {email}. Cliquez sur le lien dans le courriel pour terminer l'inscription.",
  selfRegistrationInvalidEmailAddress: "L'adresse électronique que vous avez saisie n'est pas valide.",
  selfRegistrationLink: 'Créer un compte',
  selfRegistrationRegister: 'Continuer',
  selfRegistrationText:
    "Créez un nouveau compte ici. Nous vous enverrons un lien de confirmation à l'adresse e-mail que vous avez fournie.",
  selfRegistrationTitle: 'Inscription',
  sessions: 'Sessions',
  setPassword: 'Définir un mot de passe',
  setupTwoFactorAuthentication: "Configurer l'authentification à deux facteurs",
  signup: "S'enregistrer",
  singleSignOnFailed:
    "Échec du single sign-on. Veuillez vous connecter en saisissant votre nom d'utilisateur et votre mot de passe",
  stayLoggedIn: 'Rester connecté',
  street: 'Rue',
  switchToLogin: 'Connectez-vous',
  tfaActivated: {
    twoFactorAuthenticationActivated: 'Authentification à deux facteurs activée.',
    twoFactorAuthenticationActivatedHelp: "Après avoir entré votre mot de passe vous devrez, à l'avenir, entrer un code 2FA à 6 chiffres. Pour obtenir ce code il vous faudra ouvrir <strong>l'application d'authentification</strong> que vous venez d'utiliser pour l'installation de ce compte sur votre téléphone.<br/><br/>Veuillez ensuite sélectionner le code correspondant à ce compte et le rentrer comme demandé.",
  },
  tfaManagement: "Gérer l'authentification à deux facteurs",
  tfaNumFailures: "Tentatives d'A2F échouées.",
  title: 'Titre',
  tryAgain: 'Veuillez réessayer',
  twoFactorAuthentication: 'Authentification à deux facteurs',
  twoFactorNotSetup: "L'authentification à deux facteurs est requise mais n'est pas configurée. Connexion impossible.",
  twoFactorSetup:
    "L'authentification à deux facteurs est requise mais n'est pas configurée. Veuillez suivre les instructions pour la configuration de l'authentification à deux facteurs.",
  tfaEntryPrompt: {
    enterCodePrompt: 'Veuillez entrer le code 2FA.',
    codeFromSMSHelp: "Vous avez installé l'authentification à deux facteurs par SMS pour ce compte. Veuillez entrer le code que vous avez reçu par SMS.",
    codeFromTOTPAppHelp: "Vous avez installé l'authentification à deux facteurs avec une <strong>application d'authentification</strong> pour ce compte. Pour obtenir ce code, veuillez ouvrir sur votre téléphone <strong>l'application d'authentification</strong> que vous avez utilisée pour l'installation de ce compte (p.ex. Authy, Google Authenticator ou Microsoft Authenticator).",
    codeFromTOTPAppSelectAccount: "Puis sélectionnez le code correspondant à ce compte dans l'application et entrez le ici.",
  },
  userManagement: 'Gestion des utilisateurs',
  tfaSetup: {
    pleaseScanBarcode: "Veuillez scanner le code QR ci-dessous avec votre <strong>application d'authentification</strong>, ou y entrer la clé suivante:",
    installTOTPApp: "Si vous ne disposez pas encore d'une application d'authentification, vous devez en <strong>installer une sur votre téléphone</strong>. Si vous ne savez pas quelle application choisir, nous recommendons l'une de celles-ci:",
    noPaidTOTPApp: "Remarque: Ces applications sont <strong>gratuites</strong>. Il n'est pas nécessaire d'installer une app payante.",
    setupTOTPApp: "Après avoir installé une application d'authentification sur votre téléphone, veuillez l'ouvrir et sélectionner l'action pour installer un 2FA pour <strong>un nouveau compte</strong> à l'aide d'un code QR. Cette action devrait s'intituler quelque chose comme <em>\"Installer un nouveau compte\"</em> ou <em>\"Scanner un code QR\"</em>.",
  },
  username: "Nom d'utilisateur",
  verify: 'Vérifier',
  wrongPassword: 'Mot de passe incorrect',
  zipCode: 'Code postal',
  approveChangeEmail: "Modifier l'adresse e-mail",
  changeEmailApproved: "L'adresse e-mail a été modifiée.",
  changeEmailDone: 'Terminé',
  changeEmail: "Modifier l'adresse e-mail",
  successChangeEmailRequest: "Un e-mail pour changer l'adresse e-mail a été envoyé à {email}.",
  inquiryNotPossible: "Il n'a pas été possible de déterminer si l'utilisateur peut être supprimé.",
  delete_user: 'Supprimer le compte',
  delete_user_hint:
    "Votre compte et les données associées seront supprimées. Vous ne pourrez plus vous connecter à l'application avec ce compte.",
  confirm_delete_user_hint: 'Vous êtes sur le point de supprimer votre compte. Cette action ne peut pas être annulée.',
  confirm_delete_user: 'Supprimer le compte définitivement',
  danger_zone: 'Zone de danger',
}
